import { useNavigate } from "react-router-dom";

import PageImageBacksplash from "./PageImageBacksplash";
import homeImage from "../assets/images/home.jpg";
import Button from "./ui/Button";
import { ROUTES } from "../constants/routes";
import ServiceTiles from "./ServiceTiles";
import Certifications from "./Certifications";

import styles from "./Home.module.css";

const Home = () => {
  const navigate = useNavigate();
  const navigateToServices = () => {
    navigate(ROUTES.services);
  };

  return (
    <div>
      <PageImageBacksplash image={homeImage} description="Home" />
      <div className={styles.content}>
        <div className={styles.titleBanner}>
          <h1 className={styles.titleHeader}>
            Maximize Your Impact – Leave the Bookkeeping to Us!
          </h1>
          <p className={styles.titleDescription}>
            Bookkeeping shouldn't slow you down. Our professional solutions free
            up your time to focus on driving your nonprofit's mission forward.
          </p>
        </div>

        <section className={styles.serviceSection}>
          <div className={styles.descriptionWrapper}>
            <h2 className={styles.title}>
              <b>Services</b>
            </h2>
            <div>
              <i className={styles.subtitle}>we offer</i>
            </div>
            <p>
              Elpis Bookkeeping is here to simplify your nonprofit’s financial
              journey! We provide customized bookkeeping solutions to help you
              gain control of your organization's finances and focus on your
              mission.
            </p>

            <Button
              type="Secondary"
              onClick={navigateToServices}
              className={styles.button}
            >
              Our Services
            </Button>
          </div>

          <ServiceTiles />
        </section>

        <section>
          <Certifications />
        </section>
      </div>
    </div>
  );
};

export default Home;
