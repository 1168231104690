import { PRIMARY_PHONE_NUMBER, PRIMARY_EMAIL } from "../constants/company";
import Icons from "./ui/Icons";
import ContactForm from "./ContactForm";

import styles from "./ContactUs.module.css";

const ICON_SIZE = "2.5em";

const ContactUs = () => {
  return (
    <div className={styles.wrapper}>
      <section className={styles.formSection}>
        <div className={styles.formSectionText}>
          <h1 className={styles.messageTitle}>Let's Chat</h1>
          <p className={styles.subTitle}>
            <i>Feel free to send us a message.</i>
          </p>
          <p className={styles.message}>
            Book a <i>free</i> consultation to discover how we can assist you!
          </p>

          <div className={styles.contactMethods}>
            <div className={styles.phoneWrapper}>
              <Icons name="Phone" height={ICON_SIZE} width={ICON_SIZE} />
              <h3>Phone Number</h3>
              <a
                href={`tel:${PRIMARY_PHONE_NUMBER}`}
                className={styles.phoneNumber}
              >
                {PRIMARY_PHONE_NUMBER}
              </a>
            </div>

            <div className={styles.emailWrapper}>
              <Icons name="Mail" height={ICON_SIZE} width={ICON_SIZE} />
              <h3>Email</h3>
              <p>
                <a href={`mailto:${PRIMARY_EMAIL}`} className={styles.email}>
                  {PRIMARY_EMAIL}
                </a>
              </p>
            </div>
          </div>
        </div>

        <ContactForm />
      </section>
    </div>
  );
};

export default ContactUs;
