import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import PageImageBacksplash from "./PageImageBacksplash";
import Button from "./ui/Button";
import aboutImage from "../assets/images/about.jpeg";
import ownerPicture from "../assets/images/ownerPicture.jpeg";
import { ROUTES } from "../constants/routes";
import Certifications from "./Certifications";
import useScrollToView from "../hooks/useScrollToView";

import styles from "./About.module.css";

const About = () => {
  const myElementRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  useScrollToView({ element: myElementRef, scrollPositions: "start" });

  return (
    <div className={styles.wrapper}>
      <PageImageBacksplash image={aboutImage} description="About Us" />
      <div className={styles.content} ref={myElementRef}>
        <section className={styles.ownerBackgroundSection}>
          <div className={styles.textWrapper}>
            <h1 className={styles.title}>
              You started your nonprofit to make a difference, not to manage
              bookkeeping, that's where I come in!
            </h1>
            <p>
              As an accounting professional with a diverse background, I bring
              extensive experience from roles such as an external auditor with
              one of the Big 4 accounting firms and an internal audit consultant
              for pre-IPO companies. However, my true passion lies in guiding
              organizations like yours from financial uncertainty to clarity.
            </p>
            <p>
              At Elpis Bookkeeping, I have found the most fulfilling chapter of
              my career—making a personal impact on the success of nonprofits.
              It’s not just about relieving you of the burden of bookkeeping;
              it's about empowering you to manage your finances effectively,
              allowing you to focus on your mission and make informed decisions
              that drive your organization forward.
            </p>
            <p>
              Let's meet to discuss your challenges, goals, and vision for your
              nonprofit. I'm here to listen, and by the end of our conversation,
              you'll have a dedicated partner who cares about your
              organization's mission as much as you do.
            </p>
            <p>
              Your finances will transform into a clear blueprint, providing a
              structured plan that supports the foundation of your successful
              nonprofit. Feel confident that every transaction and every detail
              is not just recorded, but strategically mapped to guide your
              organization toward success! It is my joy to walk alongside you on
              your journey of growth and impact.
            </p>
            <p>
              Outside of the world of numbers, I am a proud mother to a baby
              girl and enjoy hiking and nature walks—especially those with a
              hint of adventure. After all, a bit of excitement keeps life
              interesting! Let's embark on this exciting journey together and
              help your nonprofit thrive!
            </p>

            <h3>Why Nonprofits?</h3>

            <p>
              Shortly after opening up the virtual doors of Elpis bookkeeping, I
              had to tackle a fundamental question: who are we here to serve?
              The answer was crystal clear from the get-go: nonprofits. Growing
              up in a place where poverty was all too common, I saw firsthand
              the incredible work charitable organizations do to support those
              in need.
            </p>
            <p>
              From my early days, I remember receiving help from charitable
              groups – clothes, school supplies, even toys. It left a lasting
              impression on me. As I got older, I found myself drawn to giving
              back, volunteering whenever I could. Eventually, I dreamed of
              creating something of my own to make a difference in my community.
              That dream lives on through Elpis Bookkeeping's focus on
              nonprofits.
            </p>

            <p>
              With our expertise in accounting and business, we're here to help
              nonprofits thrive. By taking care of the financial side of things,
              we aim to free you up to do what you do best: changing lives and
              making the world a better place.
            </p>

            <Button
              onClick={() => {
                navigate(ROUTES.services);
              }}
              className={styles.action}
            >
              Services
            </Button>
          </div>

          <div>
            <img
              src={ownerPicture}
              alt="logo"
              className={styles.ownerPicture}
            />
            <div className={styles.ownerDescription}>Meklite Argaw</div>
            <div>Owner and CEO</div>
            <div>Certified Public Accountant (CPA)</div>
          </div>
        </section>

        <section className={styles.certSection}>
          <Certifications />
        </section>
      </div>
    </div>
  );
};

export default About;
