import QuickbookCertification from "./QuickbookCertification";
import digitalBookkeepingAssociation from "../assets/images/digitalBookkeepingAssociation.jpeg";
import gusto from "../assets/images/gusto.png";
import hubdoc from "../assets/images/hubdoc.png";
import cpa from "../assets/images/cpa.jpeg";

import styles from "./Certifications.module.css";

const Certifications = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Certifications</h1>

      <div className={styles.svgWrapper}>
        <div className={styles.assetWrapper} title="CPA">
          <img src={cpa} alt="CPA" className={styles.cpaImage} />
        </div>

        <div className={styles.assetWrapper} title="QuickBooks ProAdvisor">
          <QuickbookCertification />
        </div>

        <div
          className={styles.assetWrapper}
          title="Associate Digital Bookkeeper"
        >
          <img
            src={digitalBookkeepingAssociation}
            alt="Associate Digital Bookkeeper"
            className={styles.image}
          />
        </div>

        <div className={styles.assetWrapper} title="Gusto Certified Partner">
          <img
            src={gusto}
            alt="Gusto Certified Partner"
            className={styles.gusto}
          />
        </div>

        <div className={styles.assetWrapper} title="Hubdoc Certified">
          <img
            src={hubdoc}
            alt="Hubdoc Certified"
            className={styles.ranchoImage}
          />
        </div>
      </div>
    </div>
  );
};

export default Certifications;
