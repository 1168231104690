import classnames from "classnames";
import styles from "./Moodule.module.css";

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick?(): void;
};

const Moodule = ({ children, className, onClick }: Props) => {
  return (
    <div className={classnames(styles.wrapper, className)} onClick={onClick}>
      {children}
    </div>
  );
};

export default Moodule;
