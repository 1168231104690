import { Outlet, ScrollRestoration } from "react-router-dom";

import Header from "./Header";
import CallOutButton from "./CallOutButton";
import Footer from "./Footer";
import PageLoadOverlay from "./PageLoadOverlay";

import styles from "./App.module.css";

const App = () => {
  return (
    <div className={styles.app}>
      <PageLoadOverlay />
      <ScrollRestoration />
      <Header />
      <Outlet />
      <CallOutButton />
      <Footer />
    </div>
  );
};

export default App;
