import { useRef } from "react";

import ServiceDescription from "./ServiceDescription";
import { SERVICES } from "../constants/services";
import useScrollToView from "../hooks/useScrollToView";

import styles from "./Services.module.css";

const Services = () => {
  const myElementRef = useRef<HTMLDivElement | null>(null);

  useScrollToView({ element: myElementRef, scrollPositions: "start" });

  return (
    <div>
      <div className={styles.wrapper} ref={myElementRef}>
        <section className={styles.descriptionSection}>
          <h1 className={styles.title}>
            <>
              Being a <span className={styles.growing}>high-impact </span>
              nonprofit is hard. We simplify the finances for
            </>
            <span className={styles.easy}>you</span>.
          </h1>
          <p className={styles.description}>
            We are here to simplify your nonprofit's journey! Offering tailored
            bookkeeping solutions, we help you gain control of your
            organization's finances. Don't see the perfect fit listed? No need
            to worry. After meeting with you and understanding your nonprofit's
            unique needs, we can customize the right package for you.
          </p>
        </section>

        <section className={styles.servicesSection}>
          {SERVICES.map((props) => (
            <ServiceDescription {...props} key={props.title} />
          ))}
        </section>
      </div>
    </div>
  );
};

export default Services;
