import ServiceTile from "./ServiceTile";
import { SERVICES } from "../constants/services";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";

import styles from "./ServiceTiles.module.css";

const ServiceTiles = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.tilesWrapper}>
      {SERVICES.map(({ image, title }) => (
        <ServiceTile
          key={title}
          title={title}
          image={image}
          onClick={() => navigate(`${ROUTES.services}?scrollTo=${title}`)}
        />
      ))}
    </div>
  );
};

export default ServiceTiles;
