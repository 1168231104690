import classnames from "classnames";

import useIsScrolled from "../hooks/useIsScrolled";
import NavigationLinks from "./NavigationLinks";
import CompanyLogo from "./CompanyLogo";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

import styles from "./Header.module.css";

const Header = () => {
  const [isScrolled] = useIsScrolled();
  return (
    <div
      className={classnames(styles.header, isScrolled && styles.scrolledHeader)}
    >
      <Link to={ROUTES.home} className={styles.logo}>
        <CompanyLogo />
      </Link>

      <NavigationLinks
        allowToCollapse
        className={classnames(
          styles.navWrapper,
          isScrolled && styles.scrolledNavWrapper
        )}
      />
    </div>
  );
};

export default Header;
