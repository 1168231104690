import Module from "./ui/Module";

import styles from "./ServiceTile.module.css";

type Props = {
  title: string;
  image: string;
  onClick(): void;
};

const ServiceTile = ({ title, image, onClick }: Props) => {
  return (
    <Module className={styles.wrapper} onClick={onClick}>
      <img src={image} alt={title} className={styles.image} />
      <h3 className={styles.title}>{title}</h3>
    </Module>
  );
};

export default ServiceTile;
