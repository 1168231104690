import { useMemo } from "react";
import classnames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";

import NavigationLink from "./NavigationLink";
import Dropdown from "./ui/Dropdown";
import Button from "./ui/Button";
import Icons from "./ui/Icons";
import useWindowResize from "../hooks/useWindowResize";

import styles from "./NavigationLinks.module.css";

const NAVIGATION_OPTIONS = [
  { value: "/", label: "Home" },
  { value: "/services", label: "Services" },
  { value: "/about", label: "About" },
  { value: "/contactUs", label: "Contact Us" },
];

type Props = {
  className?: string;
  allowToCollapse?: boolean;
};

const NavigationLinks = ({ className, allowToCollapse = false }: Props) => {
  const location = useLocation();
  const windowWidth = useWindowResize();
  const navigate = useNavigate();

  const shouldCollapse = useMemo(() => windowWidth < 850, [windowWidth]);
  const currentPathOption = useMemo(
    () =>
      NAVIGATION_OPTIONS.find((option) => option.value === location.pathname),
    [location]
  );

  return (
    <div>
      {allowToCollapse && shouldCollapse ? (
        <Dropdown
          options={NAVIGATION_OPTIONS}
          onSelect={(option) => navigate(option.value)}
          selected={currentPathOption}
        >
          <Button className={styles.button}>
            <Icons name="Menu" />
          </Button>
        </Dropdown>
      ) : (
        <div className={classnames(styles.navWrapper, className)}>
          {NAVIGATION_OPTIONS.map((options) => (
            <NavigationLink to={options.value} key={options.value}>
              {options.label}
            </NavigationLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavigationLinks;
