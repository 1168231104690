import styles from "./PageImageBacksplash.module.css";

type Props = {
  image: string;
  description: string;
};

const PageImageBacksplash = ({ image, description }: Props) => {
  return <img src={image} alt={description} className={styles.image} />;
};

export default PageImageBacksplash;
