import { useRef } from "react";

import useScrollToView from "../hooks/useScrollToView";
import { Service } from "../constants/services";

import styles from "./ServiceDescription.module.css";

type Props = Service;

const ServiceDescription = ({
  title,
  subtitle,
  description,
  services,
  additionalServices,
  image,
}: Props) => {
  const myElementRef = useRef<HTMLDivElement | null>(null);

  // Scrolls myElement intoView if id matches scrollTo param in url
  useScrollToView({ id: title, element: myElementRef });

  return (
    <div className={styles.wrapper} ref={myElementRef}>
      <div className={styles.contentWrapper}>
        <img src={image} alt={title} className={styles.image} />

        <div className={styles.textWrapper}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subtitle}>{subtitle}</p>
          {description && <p>{description}</p>}

          <h3 className={styles.servicesTitle}>Services</h3>
          <ul className={styles.servicesList}>
            {services.map((service) => (
              <li key={service}>{service}</li>
            ))}
          </ul>
          {additionalServices?.length && (
            <div>
              <h3 className={styles.servicesTitle}>Additional Services</h3>
              <ul className={styles.servicesList}>
                {additionalServices.map((service) => (
                  <li key={service}>{service}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceDescription;
