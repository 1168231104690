import ongoing from "../assets/images/ongoing.jpg";
import oneTimeServices from "../assets/images/oneTimeServices.jpg";
import quickbooksOnboarding from "../assets/images/quickbooksOnboarding.jpg";

export type Service = {
  title: string;
  subtitle?: string;
  description?: string;
  services: ReadonlyArray<string>;
  additionalServices?: ReadonlyArray<string>;
  image: string;
};

export type Services = ReadonlyArray<Service>;

export const SERVICES: Services = [
  {
    title: "Ongoing Bookkeeping",
    subtitle: "Monthly or Quarterly",
    image: ongoing,
    description:
      "The daily tasks you want to escape. We help you stay on top of your accounts and keep your finances organized so you can focus on your mission! Monthly or quarterly reports will provide a clear picture of your nonprofit’s health, allowing you to make informed decisions with confidence.",
    services: [
      "Revenue Allocation",
      "Expense Allocation",
      "Month End Reconciliations",
      "Income Statement Report",
      "Balance Sheet Report",
      "Financial Update with Income Statement Report",
      "Available for Questions",
    ],
    additionalServices: [
      "Restricted Fund Tracking",
      "Budget Creation",
      "Donor Tracking",
      "Grant Application Support",
      "Employee Time Tracking",
      "Receipt Management",
      "Bill Payment",
    ],
  },
  {
    title: "One-time Services",
    image: oneTimeServices,
    description:
      "Your financial statements should guide all of your nonprofit's decisions — but if your books are a mess, you’re flying blind. If your financial records have been piling up, I can help. Let’s get you caught up so you can have an accurate picture of your organization’s financial health.",
    services: [
      "Historical Catch-Up (get caught up)",
      "Historical Clean-Up (messy books)",
    ],
  },
  {
    image: quickbooksOnboarding,
    title: "QuickBooks Onboarding",
    description:
      "Tired of managing your nonprofit with spreadsheets and Post-its? Or maybe you’re just starting out and need help with QuickBooks setup? Mistakes made in your initial QuickBooks setup can be time-consuming to correct. Let’s discuss your organization, your reporting and accounting needs, and I'll customize QuickBooks Online to get you set up right the first time.",
    services: ["QuickBooks Setup", "QuickBooks Training"],
  },
];
