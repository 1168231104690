import { useEffect } from "react";
import classnames from "classnames";

import preLoadAnimation from "../animations/preLoadAnimation";
import { NAME } from "../constants/company";

import styles from "./PageLoadOverlay.module.css";

const PageLoadOverlay = () => {
  useEffect(() => {
    preLoadAnimation();
  });

  return (
    <div
      className={classnames(styles.loadingOverlay, "preloader")}
      id="loadingOverlay"
    >
      <div className={classnames(styles.content, "texts-container")}>
        {NAME.split(" ").map((text) => (
          <span key={text}>{text}</span>
        ))}
      </div>
    </div>
  );
};

export default PageLoadOverlay;
