import { useNavigate, useLocation } from "react-router-dom";

import Button from "./ui/Button";
import { ROUTES } from "../constants/routes";

import styles from "./CallOutButton.module.css";

const CallOutButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (ROUTES.contactUs === location.pathname) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Button
        type="Primary"
        onClick={() => {
          navigate(`${ROUTES.contactUs}?scrollTo=ContactForm`);
        }}
        className={styles.button}
      >
        <span className={styles.text}>Schedule Call</span>
      </Button>
    </div>
  );
};

export default CallOutButton;
