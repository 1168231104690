import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  element: React.MutableRefObject<HTMLElement | null>;
  id?: string;
  scrollPositions?: "center" | "end" | "nearest" | "start";
  delay?: number;
};

// Scrolls element intoView if id matches scrollTo param in url
const useScrollToView = ({
  element,
  id,
  scrollPositions = "center",
  delay = 0,
}: Props) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const elementToScrollTo = searchParams.get("scrollTo");

    const shouldScroll = !!id
      ? elementToScrollTo && element.current && elementToScrollTo === id
      : element.current;

    if (shouldScroll) {
      setTimeout(() => {
        element.current?.scrollIntoView({
          behavior: "smooth",
          block: scrollPositions,
        });
      }, delay);
    }
  }, [searchParams, id, element, scrollPositions, delay]);
};

export default useScrollToView;
