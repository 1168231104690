import { useRef } from "react";
import { InlineWidget } from "react-calendly";

import Module from "./ui/Module";
import useScrollToView from "../hooks/useScrollToView";
import { CALENDLY_LINK } from "../constants/company";
import { PRIMARY_COLOR, QUATERNARY_COLOR } from "../constants/colors";

import styles from "./ContactForm.module.css";

const ContactForm = () => {
  const myElementRef = useRef<HTMLDivElement | null>(null);

  // Scrolls myElement intoView if id matches scrollTo param in url
  useScrollToView({
    element: myElementRef,
    id: "ContactForm",
    scrollPositions: "start",
  });

  return (
    <div className={styles.wrapper} ref={myElementRef}>
      <div className={styles.formBackground} />
      <Module className={styles.module}>
        <InlineWidget
          url={CALENDLY_LINK}
          styles={{ minWidth: "300px", height: "900px" }}
          pageSettings={{
            primaryColor: PRIMARY_COLOR,
            textColor: QUATERNARY_COLOR,
          }}
        />
      </Module>
    </div>
  );
};

export default ContactForm;
